import React from "react"

const IcoGithub = props => (
  <svg width={24} height={23} {...props}>
    <path
      d="M12 0C5.37 0 0 5.28 0 11.792c0 5.211 3.438 9.63 8.205 11.188.6.11.82-.254.82-.567 0-.28-.01-1.022-.015-2.005-3.338.711-4.042-1.582-4.042-1.582-.546-1.361-1.335-1.725-1.335-1.725-1.087-.73.084-.716.084-.716 1.205.083 1.838 1.215 1.838 1.215 1.07 1.803 2.809 1.282 3.495.98.108-.762.417-1.282.76-1.577-2.665-.295-5.466-1.309-5.466-5.827 0-1.287.465-2.339 1.235-3.164-.135-.298-.54-1.497.105-3.121 0 0 1.005-.317 3.3 1.208.96-.262 1.98-.392 3-.398 1.02.006 2.04.136 3 .398 2.28-1.525 3.285-1.208 3.285-1.208.645 1.624.24 2.823.12 3.12.765.826 1.23 1.878 1.23 3.165 0 4.53-2.805 5.527-5.475 5.817.42.354.81 1.077.81 2.182 0 1.578-.015 2.846-.015 3.229 0 .31.21.678.825.56C20.565 21.417 24 16.995 24 11.792 24 5.28 18.627 0 12 0"
      fillRule="nonzero"
    />
  </svg>
)

export default IcoGithub
