import React from "react"

const IcoMail = props => (
  <svg width={26} height={18} {...props}>
    <path
      d="M1.95 0c-.255 0-.498.057-.721.151l11.03 10.266c.476.444.984.444 1.462 0L24.771.151A1.848 1.848 0 0024.05 0H1.95zM.02 1.785c-.012.094-.02.194-.02.292v13.846C0 17.073.87 18 1.95 18h22.1c1.08 0 1.95-.926 1.95-2.077V2.077c0-.098-.008-.198-.02-.292L15 11.985a2.93 2.93 0 01-4.021 0L.02 1.786z"
      fillRule="nonzero"
    />
  </svg>
)

export default IcoMail
